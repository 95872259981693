import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
export default {
  name: 'AssetsInLogDetail2',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      from: '',
      sourceId: '',
      sourceTitle: '',
      sourceType: '',
      inApplicant: '',
      spaceFullCode: '',
      spaceName: '',
      remark0: '',
      remark: '',
      stockUser: '',
      detailList: [],
      stockUserSignUrl: '',
      stockInImgList: [],
      outKeeper: '',
      outSpaceName: '',
      productPropertyMap,
      totalPrice: ''
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getStockInDetail(this.id).then(res => {
        let item = res.data.data;
        this.sourceId = item.sourceId;
        this.sourceTitle = item.sourceTitle;
        this.sourceType = item.sourceType;
        this.detailList = item.detailList || [];
        this.spaceFullCode = item.spaceFullCode;
        this.spaceName = item.spaceName;
        this.stockMethod = item.stockMethod;
        this.projectId = item.projectId;
        this.stockUser = item.stockUser;
        this.totalPrice = item.totalAmount;
        this.stockUserSignUrl = item.stockUserSignUrl;
        this.stockInImgList = item.stockInImgList ? item.stockInImgList.map(i => {
          return {
            url: i.fileUrl
          };
        }) : [];
        this.remark = item.remark;
      });
    }
  }
};